import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../firebase";
import { ref, get } from "firebase/database";

const RedirectPage = () => {
  const { path } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const redirectUser = async () => {
      try {
        const redirectRef = ref(db, `redirects/${path}`);
        const snapshot = await get(redirectRef);

        if (snapshot.exists()) {
          const redirectData = snapshot.val();
          const userAgent = navigator.userAgent.toLowerCase();
          const isBot = /bot|crawler|spider|robot|crawling|facebook|meta/i.test(userAgent);

          const redirectUrl = isBot
            ? redirectData.botLink
            : redirectData.userLink;

          if (redirectUrl) {
            window.location.href = redirectUrl;
          } else {
            navigate("/404");
          }
        } else {
          navigate("/404");
        }
      } catch (error) {
        console.error("Error during redirection:", error);
        navigate("/error");
      } finally {
        setIsLoading(false);
      }
    };

    redirectUser();
  }, [path, navigate]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return null;
};

export default RedirectPage;
