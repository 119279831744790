// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AdminPanel from "./components/AdminPanel";
import MainApp from "./components/MainApp";
import RedirectPage from "./components/RedirectPage";
import SignIn from "./components/SignIn";
import PrivateRoute from "./components/PrivateRoute";
import NotFoundPage from "./components/NotFound";
import ErrorPage from "./components/ErrorPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/signin" element={<SignIn />} />
        {/* Route for the admin panel */}
        <Route
          path="/admin"
          element={
            <PrivateRoute>
              <AdminPanel />
            </PrivateRoute>
          }
        />
        {/* Default route for the main app */}
        <Route path="/" element={<MainApp />} />
        <Route path="/404" element={<NotFoundPage />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/:path" element={<RedirectPage />} />
      </Routes>
    </Router>
  );
}

export default App;
