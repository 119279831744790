import React, { useState, useEffect } from "react";
import { db } from "../firebase"; // Import Realtime Database from firebase.js
import { ref, set, onValue, get } from "firebase/database"; // Import 'get' for reading data from Realtime Database
import "./admin.css";

const AdminPanel = () => {
  const [redirects, setRedirects] = useState({});
  const [link, setLink] = useState("");
  const [userLink, setUserLink] = useState("");
  const [botLink, setBotLink] = useState("");
  const [error, setError] = useState("");
  const [baseUrl, setBaseUrl] = useState(window.location.origin);

  // Fetch redirects from Realtime Database when component mounts
  useEffect(() => {
    const redirectsRef = ref(db, "redirects");
    onValue(redirectsRef, (snapshot) => {
      const data = snapshot.val();
      setRedirects(data || {});
    });
  }, []);

  // Function to update redirect in Realtime Database
  const handleUpdateRedirect = async () => {
    setError("");

    if (!link || !userLink || !botLink) {
      setError("All fields are required.");
      return;
    }

    try {
      const redirectRef = ref(db, `redirects/${link}`);
      await set(redirectRef, {
        userLink: ensureHttps(userLink),
        botLink: ensureHttps(botLink),
      });

      alert("Redirect updated successfully!");
      setLink("");
      setUserLink("");
      setBotLink("");
    } catch (error) {
      console.error("Error updating redirect:", error);
      setError("Error updating redirect. Please try again.");
    }
  };

  const handleEdit = (link) => {
    setLink(link);
    setUserLink(redirects[link].userLink || "");
    setBotLink(redirects[link].botLink || "");
  };

  const ensureHttps = (url) => {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return `https://${url}`;
    }
    return url;
  };

  // Function to generate a unique random link using Realtime Database to check for uniqueness
  const generateRandomLink = async () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;

    // Generate a random 5-character string
    for (let i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    // Check if the generated link already exists in Realtime Database
    const linkRef = ref(db, `redirects/${result}`);
    const snapshot = await get(linkRef);

    if (snapshot.exists()) {
      // If the link exists, recursively generate a new one
      console.log("Link already exists, generating a new one...");
      generateRandomLink();
    } else {
      // Set the unique link in the input field
      setLink(result);
    }
  };

  const handleCopyLink = (redirectPath) => {
    const fullLink = `${baseUrl}/${redirectPath}`;
    navigator.clipboard
      .writeText(fullLink)
      .then(() => {
        alert(`Copied to clipboard: ${fullLink}`);
      })
      .catch((error) => {
        console.error("Failed to copy link:", error);
      });
  };

  return (
    <div className="admin-panel">
      <h1>Admin Panel</h1>
      <div className="update-redirect">
        <h2>Update Redirect</h2>
        {error && <p className="error-message">{error}</p>}
        <div className="input-with-button">
          <input
            type="text"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            placeholder="Link (required)"
          />
          <button className="generate-btn" onClick={generateRandomLink}>
            Generate
          </button>
        </div>
        <input
          type="text"
          value={userLink}
          onChange={(e) => setUserLink(e.target.value)}
          placeholder="User Link (required)"
        />
        <input
          type="text"
          value={botLink}
          onChange={(e) => setBotLink(e.target.value)}
          placeholder="Bot Link (required)"
        />
        <button onClick={handleUpdateRedirect}>Update Redirect</button>
      </div>

      <div className="current-redirects">
        <h2>Current Redirects</h2>
        <ul>
          {Object.entries(redirects).map(([redirectPath, redirectData]) => (
            <li key={redirectPath}>
              <div>
                <strong>{redirectPath}</strong>:
                <button className="copy-btn" onClick={() => handleCopyLink(redirectPath)}>Copy Link</button>
                <ul>
                  <li>User Link: {redirectData.userLink}</li>
                  <li>Bot Link: {redirectData.botLink}</li>
                </ul>
              </div>
              <button onClick={() => handleEdit(redirectPath)}>Edit</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AdminPanel;
