import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDUkMUCTXFtIbJ5zBMPxa2OywN3ZeV5gjk",
  authDomain: "link-cloaker-ebffd.firebaseapp.com",
  databaseURL:
    "https://link-cloaker-ebffd-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "link-cloaker-ebffd",
  storageBucket: "link-cloaker-ebffd.appspot.com",
  messagingSenderId: "160226649617",
  appId: "1:160226649617:web:4456fb1401c61af5e5f3b0",
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app);

export { db, auth };
