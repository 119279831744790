import React from "react";
import { Link } from "react-router-dom";
import './ErrorPage.css'; // CSS file for styling

const NotFoundPage = () => {
  return (
    <div className="error-container">
      <h1 className="error-code">404</h1>
      <h2 className="error-message">Oops! Page Not Found</h2>
      <p className="error-description">
        Sorry, the page you’re looking for doesn’t exist. It might have been removed or the URL is incorrect.
      </p>
      <Link to="/" className="error-button">
        Go Back Home
      </Link>
    </div>
  );
};

export default NotFoundPage;
