import './mainapp.css'

const MainApp = () => {
  return (
    <>
      <section class="hero">
        <div class="hero-content">
          <h1>Discover Your Favorite Football Jerseys</h1>
          <p>
            High-quality jerseys for your favorite teams. Order yours today!
          </p>
          <a href="#products" class="btn-primary">
            Shop Now
          </a>
        </div>
      </section>

      <section id="products" class="product-section">
        <h2>Our Jerseys</h2>
        <div class="product-grid">
          <div class="product-card">
            <img src="jersey1.jpg" alt="Team Jersey 1" />
            <h3>Bobby Boucher #9 The Waterboy Adam</h3>
            <p>$24.14</p>
            <a href="https://www.amazon.sg/Boucher-Waterboy-Sandler-Football-3X-Large/dp/B0BQRH5YMX/ref=sr_1_1?dib=eyJ2IjoiMSJ9.9LL8Px-azEOXGfqAypj-AFd3YB2VavArSj4qEcG1oIAoN9T_NamaNtMOkYOX6qxi7SA41xaHbaG1tV3zPFfeHDE2hGyx5StIEYhNHhot2BBX7wF-RLNOiNfLt-dGKclMk3rxNvaPG55gqw_XsUYC7zhpyNhFIomB6UD3G9OpAZbTGu68o__9bOvDFffR5fGmi83F2aisC8abFipttI7JL9gPbZ_pSvc4T5AWT4T8YcvW1j6SAmE55lxVgxxSlTVSwbGY-FW31A-uRTBLRomMOTL7SImu11vyx1tmxht45OM._7PJg2GvO5kXJouj0mxAA8mBrYbUDlzD3t0If4Gu5xs&dib_tag=se&keywords=football+jerseys&qid=1727808205&sr=8-1" class="btn-secondary">
              Buy Now
            </a>
          </div>
          <div class="product-card">
            <img src="jersey2.jpg" alt="Team Jersey 2" />
            <h3>Blank Football Jersey Mesh</h3>
            <p>$22.96</p>
            <a href="https://www.amazon.sg/EALER-Football-Athletic-Practice-Uniform/dp/B0BX5P9912/ref=sr_1_2?dib=eyJ2IjoiMSJ9.9LL8Px-azEOXGfqAypj-AFd3YB2VavArSj4qEcG1oIAoN9T_NamaNtMOkYOX6qxi7SA41xaHbaG1tV3zPFfeHDE2hGyx5StIEYhNHhot2BBX7wF-RLNOiNfLt-dGKclMk3rxNvaPG55gqw_XsUYC7zhpyNhFIomB6UD3G9OpAZbTGu68o__9bOvDFffR5fGmi83F2aisC8abFipttI7JL9gPbZ_pSvc4T5AWT4T8YcvW1j6SAmE55lxVgxxSlTVSwbGY-FW31A-uRTBLRomMOTL7SImu11vyx1tmxht45OM._7PJg2GvO5kXJouj0mxAA8mBrYbUDlzD3t0If4Gu5xs&dib_tag=se&keywords=football+jerseys&qid=1727808205&sr=8-2" class="btn-secondary">
              Buy Now
            </a>
          </div>
          <div class="product-card">
            <img src="jersey4.jpg" alt="Team Jersey 3" />
            <h3>Men 9 Bobby Boucher Football Jersey</h3>
            <p>$29.16</p>
            <a href="https://www.amazon.sg/Boucher-Football-Jersey-Waterboy-Sandler/dp/B0C3HHTK4J/ref=sr_1_3?dib=eyJ2IjoiMSJ9.9LL8Px-azEOXGfqAypj-AFd3YB2VavArSj4qEcG1oIAoN9T_NamaNtMOkYOX6qxi7SA41xaHbaG1tV3zPFfeHDE2hGyx5StIEYhNHhot2BBX7wF-RLNOiNfLt-dGKclMk3rxNvaPG55gqw_XsUYC7zhpyNhFIomB6UD3G9OpAZbTGu68o__9bOvDFffR5fGmi83F2aisC8abFipttI7JL9gPbZ_pSvc4T5AWT4T8YcvW1j6SAmE55lxVgxxSlTVSwbGY-FW31A-uRTBLRomMOTL7SImu11vyx1tmxht45OM._7PJg2GvO5kXJouj0mxAA8mBrYbUDlzD3t0If4Gu5xs&dib_tag=se&keywords=football+jerseys&qid=1727808205&sr=8-3" class="btn-secondary">
              Buy Now
            </a>
          </div>
        </div>
      </section>

      <section class="about-section">
        <h2>About Our Jerseys</h2>
        <p>
          Our football jerseys are crafted with high-quality materials, offering
          both comfort and durability. Whether you are on the pitch or supporting
          your team, our jerseys keep you stylish.
        </p>
      </section>

      <section class="reviews-section">
        <h2>What Our Customers Say</h2>
        <div class="review">
          <p>
            "Top Notch quality, best shop in town!"
          </p>
          <h4>- John</h4>
        </div>
        <div class="review">
          <p>
            "Fast shipping and the jersey feels great to wear."
          </p>
          <h4>- Melissa</h4>
        </div>
      </section>

      <footer class="footer">
        <p>&copy; 2024 Football Jerseys. All rights reserved.</p>
      </footer>
    </>
  );
};

export default MainApp;
